
.corporate-console-login-box {
  /* margin-top: 57px; */
  position: relative;
  margin-top: 0px;
}

.corporate-console-login-box .btn-info {
  background: #71b5cd;
  border: #71b5cd;
  margin-left: 17px;
}

.btn-secondary {
  color: #fff;
  background-color: #71b5cd !important;
  border-color: #f7f7f7 !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 7px 18px;
}

.sejall-login-img {
  margin-top: -410px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none !important;
  border: 1px solid grey !important;
  background: transparent !important;
  height: 52px;
  padding: 12px;
  border-radius: 6px !important;
}

.corporate-console-login-box a {
  font-size: 14px;
}

.corporate-console-login-box label {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.corporate-console-login-cover {
  padding: 1rem !important;
  background: #fff;
  border-radius: 10px !important;
  width: 85%;
  /* margin-left: 50px; */
  /* height: 384px; */
}

/* .corporate-console-login-box #login {
  margin-top: 22px;
} */

.raghnall-company-login-details li {
  list-style: none;
  margin-bottom: 5px;
  font-size: 14px;
}

.raghnall-company-login-details i {
  color: #88bd2f;
}

.raghnall-company-login-details {
  margin-top: 582px;
  margin-left: 10px;
  width: 100%;
}

span.aghnall-company-login-details-title {
  font-weight: bold;
}

.raghnall-company-login-details ul {
  padding: 0;
}

.corporate-login-name h3 {
  font-size: 18px;
  color: #71b5cd;
  margin: 16px 0px;
  font-weight: bold;
}

.corporate-console-login-tips-wrap {
  margin-top: 207px;
  width: 80%;
}

.corporate-console-login-tips-wrap li {
  font-size: 15px;
}

.corporate-console-login-tips-wrap ul {
  padding: 0;
  padding-left: 26px;
}

.corporate-console-login-tips-img {
  padding-left: 46px;
}

.side-menu {
  width: 240px;
  z-index: 10;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 70px !important;
}

.login-tab-content-cover {
  margin-left: 0px;
}

.wrapper {
  overflow: hidden;
  /* max-width: 375px; */
  background: #fff;
  padding: 18px;
  border-radius: 5px;
  margin-left: 36px;
  margin-bottom: 50px;
}

.wrapper .title-text {
  display: flex;
  width: 205%;
}
.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 20px;
  margin-bottom: 12px;
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 3px 0 8px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.slide-controls a {
  color: white;
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background-color: #05e8ba;
  background-image: linear-gradient(315deg, #05e8ba 0%, #087ee1 74%);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type="radio"] {
  display: none;
}
#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.login {
  color: #fff;
}
#login:checked ~ label.signup {
  color: #fff;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

form label.error {
  color: red;
}

.form-inner form .field input:focus {
  border-color: #531716;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
  font-size: 12px;
}
form .field input:focus::placeholder {
  color: #b3b3b3;
}
.form-inner form .pass-link {
  margin-top: 5px;
}
.form-inner form .signup-link {
  text-align: center;
  margin-top: 18px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #000;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}
form .btn {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

form .btn:hover .btn-layer {
  left: 0;
}

.corporate-console-login-btn {
  margin-top: 70px;
  margin-left: 20px;
  margin-bottom: 25px;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  background: #fff;
  margin-left: 15px;
  border-radius: 6px;
  color: #000;
  border: 2px solid #71b5cd;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  text-align: center;
  padding: 10px 4px;
  font-size: 14px;
  width: 75%;
}

.nav-tabs .nav-link:hover {
  border: 2px solid #71b5cd;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffff;
  border: 2px solid #71b5cd;
  background-color: #71b5cd;
  background-image: -webkit-linear-gradient(45deg, #71b5cd 0, #368fb0 100%);
  border-radius: 5px;
}


.policyera-corporate-login-cover {
    height: 100vh;
    overflow: hidden;
    background-image: url("./Assets/hr-login-cover-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.policyera-corporate-login-image {
  background: #e6f2ff;
  margin-left: -10px;
  border-radius: 0 26px 26px 0;
  margin-left: -25px;
  text-align: center;
  height: 100%;
  text-align: center;
}

.policyera-corporate-login-image img {
  width: 90%;
}

.policyera-corporate-login-form img {
  width: 170px;
}

.policyera-corporate-login-logo {
  text-align: center;
  margin: 20px 0;
}
.choice-banner-img-box img {
  width: 70%;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
}
.choice-banner-cover h4 {
  font-size: 15px;
}
.choice-banner-cover {
  padding: 35px 15px;
  border-bottom: 1px solid #69696975;
  border-top: 1px solid #6969696e;
  background-color: white;
}

/* TABLET LANDSCAPE & iPads (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  body {
    background-image: none !important;
  }

  .corporate-console-login-cover {
    padding: 1rem !important;
    background: #f7f7f7;
    border-radius: 10px !important;
    width: 100%;
    margin-left: 0px;
    height: auto;
  }

  .raghnall-company-login-details ul {
    padding: 0;
    padding-left: 0px;
  }

  .corporate-console-login-box {
    padding: 15px;
  }

  .sejall-login-img {
    margin-top: -410px;
    display: none;
  }

  .corporate-console-login-background {
    /* background-image: url(../images/file-book-mobile.jpg) !important; */
    padding: 0px 0px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-size: cover !important;
    width: 100%;
    height: 806px;
    overflow-x: scroll;
  }

  .corporate-console-login-tips-wrap {
    display: none;
  }

  .raghnall-login-form-wraper .container-fluid {
    padding: 15px;
    display: none;
  }

  ul#side-menu .active {
    color: #000 !important;
  }

  .corporate-console-login-btn {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 25px;
  }

  .login-tab-content-cover {
    margin-left: 0px;
  }

  .policyera-corporate-login-image {
    background: none;
    margin-left: 0px;
    border-radius: 0 26px 26px 0;
    margin-left: -25px;
    text-align: auto;
    height: 100%;
    text-align: center;
  }

  .nav-item.col-lg-6.col-6 {
    padding: 0;
  }

  .wrapper {
    margin-left: 0;
    width: 80%;
  }
}

/* New Styles Starts from Here-=-=-=-==-=-=-=-=-==-==-=-= */
.left-banner-wrapper {
  padding: 45px;
  margin-top: 85px;
}
.slide-controls-box {
  height: 100%;
  display: flex;
  background-color: #00ac69;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left-header h4 {
  font-size: 35px;
  color: white;
  margin-bottom: 45px;
}
.left-img-box img {
  width: 100%;
}
.left-img-box {
  margin-top: 130px;
  margin-left: -50px;
}
.left-header {
  margin-bottom: -39px;
  margin-top: 100px;
}

/* #login:checked ~ label.login {
  cursor: default;
  user-select: none;
  background: #ffff !important;
  color: #403939;
  border-left: 10px solid #922b8c;
} */

label.slide.login.signup-active {
  color: black !important;
}
.slide-controls label a {
  text-decoration: none;
  color: white;
}

 label.signup-active {
  cursor: default;
  background: #ffff !important;
  color: #403939;
  border-left: 10px solid #922b8c;
}
.login-content {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
}
.login-small-icon img {
  width: 30px;
  margin-right: 15px;
}
.login-small-heading {
    font-size: 20px;
    color: #0d68b1;
    font-weight: 600;
}
.wrapper {
  overflow: hidden;
  background: #fff;
  padding: 0px;
  border-radius: 5px;
  margin-left: 36px;
  margin-bottom: 50px;
  width: 100% !important;
  margin: 26px auto;
}
.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
form .btn .btn-layer {
  height: 97%;
  width: 150px;
  position: absolute;
  top: 0px;
  transition: all 0.4s ease;
  margin: 0 auto;
  background: transparent linear-gradient(107deg, #f28621 0%, #922b8c 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
form .btn input[type="submit"] {
  height: auto !important;
  width: 200px;
  z-index: 1;
  position: relative;
  background: none;
  border: none !important;
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 auto;
}
.broker-log-content {
  display: flex;
  margin-bottom: 35px;
  margin-left: -15px;
}
.form-inner form .field input {
  height: 90%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
  padding: 13px;
  box-shadow: 0px 3px 6px #61616128;
  border: 1px solid #40393940;
}
div#get_info label {
  position: absolute;
  top: -9px;
  background-color: white;
  left: 15px;
  padding: 0px 5px;
  font-weight: 500;
  font-size: 16px;
}
label.mobile_no_label {
  position: absolute;
  top: -8px;
  padding: 0px 5px;
  left: 8px;
  background-color: white;
}
#login:checked ~ label.login span img {
  width: 15px;
  margin-left: 10px;
  opacity: 1;
}
#signup:checked ~ label.signup span img {
  width: 15px;
  margin-left: 10px;
  opacity: 1;
}
img.arrow-icon {
  width: 15px;
  opacity: 0;
}
.slide-controls .slide {
  width: 101%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
  margin: 0px !important;
  padding: 8px 0px;
}

form .btn:hover .btn-layer {
  height: 97%;
  width: 200px;
  position: absolute;
  top: 0px;
  transition: all 0.4s ease;
  /* background: transparent linear-gradient(107deg, #c20a00 0%, #003e9a 100%) 0%
		0% no-repeat padding-box; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.policyera-corporate-login-form {
  padding: 25px;
  /* height: 400px; */
  position: absolute;
  width: 370px;
  border-radius: 20px;
  margin-top: -60px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.slide-controls label a {
  text-decoration: none;
}
 
/* label.slide.login.active {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  background: #ffff !important;
  color: #403939;
  border-left: 10px solid #922b8c;
} */
.login-header h4 {
    font-weight: 600;
    font-size: 32px;
}
.policyera-corporate-login-wrapper {
  height: 100vh !important;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .policyera-corporate-login-wrapper {
    height: auto;
    overflow: scroll !important;
  }
  #signup:checked ~ label.login {
    color: black;
  }
  #login:checked ~ label.signup {
    color: black;
  }
  .policyera-corporate-login-form {
    padding: 25px 8px;
    position: absolute;
    width: 100%;
    border-radius: 20px;
    top: 40px;
    background-color: white;
    margin-top: 130px;
    box-shadow: none;
}
  .left-banner-wrapper {
    padding: 9px;
    height: auto;
  }
  .slide-controls-box {
    height: 100%;
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
  }
  #login:checked ~ label.login {
    cursor: default;
    user-select: none;
    background: #f78830 !important;
    color: white;
    border-left: 10px solid #253238;
  }
  #signup:checked ~ label.signup {
    cursor: default;
    background: #f78830 !important;
    color: white;
    border-left: 10px solid #253238;
  }
  div#get_info label {
    top: -10px;
  }
  .left-header {
    margin-bottom: -39px;
    margin-top: 31px;
  }
  .left-img-box img {
    width: 80%;
  }
  .left-img-box {
    margin-top: 73px;
    margin-left: -10px;
  }
  .left-banner-wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 150px;
    margin-top: 150px;
}
  .left-header h4 {
    font-size: 24px;
    color: white;
    margin-bottom: 45px;
  }
  .slide-controls .slide {
    font-size: 16px;
  }
  .slide-controls {
    position: absolute;
    right: -107px;
    top: 16%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
  .wrapper {
    background: #fff;
    padding: 18px;
    border-radius: 5px;
    margin-left: 36px;
    margin-bottom: 50px;
    width: 100%;
    margin: 26px auto;
  }
  .broker-log-content {
    display: flex;
    margin-bottom: 35px;
    margin-left: -15px;
    justify-content: center;
    margin-top: 25px;
  }
}
